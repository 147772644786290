.body {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 20px 0;
  width: 100%;

  .element,
  .checkboxElement {
    display: flex;
    padding: 5px 0;
    width: 100%;
    justify-content: space-between;
  }

  .name,
  .checkboxName {
    padding: 10px;
    width: 20%;
    font-weight: 600;
    align-self: center;
  }

  .field,
  .fieldText {
    display: flex;
    width: 100%;
  }
  .fieldText {
    align-items: center;
    font-size: 18px;
    padding-left: 15px;
  }

  .fieldWithButton {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
  }

  @media (max-width: 1100px) {
    .element {
      flex-direction: column;
    }
    .name {
      width: 100%;
    }
    .checkboxName {
      display: none;
    }
  }
}
