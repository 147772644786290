@import "../../mixins/mixins";

.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 30px;
  width: 100%;

  .element {
    display: flex;
    padding: 10px 0;
    width: 100%;
    justify-content: space-around;
  }

  .name {
    padding: 10px;
    width: 30%;
    color: rgb(117, 117, 117);
    align-self: center;
    padding-right: 30px;

    @media (max-width: 1050px) {
      width: 50%;
    }
  }

  .field,
  .filesContainer {
    display: flex;
    align-self: center;
    width: 100%;

    @media (min-width: 700px) {
      min-width: 300px;
    }
  }
  .filesContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }

  .card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: auto;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 10px;
    // box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
    background-color: #fff;
    max-width: 600px;
    box-sizing: border-box;
  }

  .cardContent {
    display: flex;
    align-items: center;
    padding: 1px;
    width: calc(100% - 70px);
    max-width: clamp(200px, 50vw, 400px);
  }

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .text {
    word-wrap: break-word;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
    overflow: hidden;
    padding-left: 15px;

    @media (max-width: 1050px) and (min-width: 800px) {
      max-width: 180px;
    }
    @media (max-width: 387px) {
      max-width: 100px;
    }
    @media (max-width: 325px) {
      max-width: 80px;
    }
  }
  .warn {
    color: rgb(212, 16, 16);
    font-weight: 600;
  }
  .buttonContainer {
    display: flex;
    gap: 10px;
  }

  .downloadButton,
  .uploadButton {
    @include button(rgba(40, 107, 42, 0.7));
    // @include button(rgba(41, 88, 67, 0.7));

    &:hover {
      background-color: rgba(28, 107, 31, 0.8);
    }

    &:active {
      background-color: darken(rgba(28, 107, 31, 0.7), 3%);
    }

    span {
      padding-right: 7px;
      font-family: "Montserrat", sans-serif;
    }
  }

  .deleteButton {
    @include button(rgba(188, 18, 18, 0.86));

    &:hover {
      background-color: rgba(188, 18, 18, 0.8);
    }

    &:active {
      background-color: darken(rgba(188, 18, 18, 0.7), 3%);
    }

    span {
      padding-right: 7px;
      font-family: "Montserrat", sans-serif;
    }
  }

  .uploadButton,
  .buttonDisabled {
    min-width: 206px;
    min-height: 45px;
    display: flex;
    justify-content: center;
  }

  .buttonDisabled {
    @include button(#fcfcfc);
    color: rgb(109, 109, 109);
    span {
      padding-right: 7px;
      font-family: "Montserrat", sans-serif;
    }
  }

  .btnContainer {
    @include btn-container;
  }

  .downloadLink {
    text-decoration: none;
    color: inherit;
    display: inline-block;
    cursor: pointer;
  }

  .downloadLink:hover {
    color: inherit;
  }

  @media (max-width: 840px) {
    .element {
      flex-direction: column;
    }
    .name {
      width: 100%;
      padding: 15px 0;
    }
    .field,
    .filesContainer {
      padding-left: 0;
    }
  }
  @media (max-width: 345px) {
    .filesContainer {
      .text {
        font-size: small;
      }
    }
  }
  .line {
    height: 1px;
    border: 0;
    background: #e6e6e6;
    margin: 10px 0;
    width: 100%;
  }
}

.loaderContainer {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.filesNames {
  display: flex;
  flex-direction: column;
  align-self: center;
  width: 100%;
  gap: 20px;
}

.addFileUI {
  display: flex;
  width: 100%;
  gap: 20px;
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 3px;
  background-color: #fff;
  max-width: 600px;
  box-sizing: border-box;
  justify-content: space-between;

  span {
    align-self: center;
    width: calc(100% - 70px);
    max-width: clamp(200px, 50vw, 400px);
    word-wrap: break-word;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
    overflow: hidden;
    padding-left: 15px;
  }

  .deleteFile {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    background-color: white;
    height: 50px;
    width: 50px;
    cursor: pointer;

    .icon {
      color: #ea4c3a;
      height: 50px;
      width: 50px;
    }
  }
}
