.header {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  gap: 20px;
  top: 64px;

  @media (max-width: 993px) {
    flex-wrap: wrap;
    gap: 10px;
    .h2 {
      height: 50px;
    }
  }
  @media (max-width: 300px) {
    .h2 {
      font-size: 14px;
    }
  }
}
