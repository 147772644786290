.body {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 20px;
  width: 100%;
  font-family: "Montserrat", sans-serif;
}

.infoContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  gap: 50px;
  width: 100%;
}

.line {
  height: 1px;
  border: 0;
  background: #e6e6e6;
  margin: 20px 0;
  width: 100%;
}

.iconContainer,
.deleteIconContainer {
  display: flex;
  align-items: center;
  min-width: 40px;
  min-height: 40px;
  padding: 0 20px;
  cursor: pointer;
}
.deleteIconContainer {
  padding: 20px;
  border: none;
  background-color: white;
}
.icon {
  min-width: 40px;
  min-height: 40px;
  color: #ed6d03;
}

@media (max-width: 600px) {
  .infoContainer {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    position: relative;
  }
  .deleteIconContainer {
    position: absolute;
    padding: 0;
    align-self: flex-end;
    top: 65%;
  }
}
