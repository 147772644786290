.container {
  display: flex;
  flex-direction: column;
  padding: 10px 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  color: #8b8fd763;
}

.labelContainer {
  display: flex;
  flex-direction: row;
  gap: 5px;

  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 0.3s ease;
  }

  .icon.open {
    transform: rotate(180deg);
  }

  .arrow {
    cursor: pointer;

    &:hover {
      color: #dfdfdf;
    }

    &:active {
      color: darken(#c6c6c6, 3%);
    }
  }
}

.filterModal {
  display: flex;
  background-color: #666aad;
}

.sort {
  padding: 10px;
  align-self: center;
  color: #fcfcfc;
  cursor: pointer;
}
