.spinner {
  display: flex;
  gap: 5px;

  &[data-is-auth="true"] .dot {
    background-color: #69a4df;
  }
}

.dot {
  width: 8px;
  height: 8px;
  background-color: #b7cde2;
  border-radius: 50%;
  animation: bounce 0.6s infinite alternate;
}

.dot:nth-child(2) {
  animation-delay: 0.2s;
}

.dot:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes bounce {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-8px);
  }
}
