.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 4px 6px -2px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  backdrop-filter: blur(10px);
  // background-color: #1876D2;
  //background-color: #205081;
  //background-color: #386b9f;
  // background-color: #0f4780ec;
  backdrop-filter: blur(6px);
  background-color: rgba(255, 255, 255, 0.7);
  top: 0;
  height: 64px;
  width: 100%;
  padding: 15px 10px;
  z-index: 9999;
  position: fixed;
  transition: box-shadow 0.2s ease-in-out, background 0.4s;
  will-change: transform;
  text-align: flex-end;
  font-family: "Montserrat", sans-serif;

  .imageContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;

    img {
      height: 60px;
      width: 70px;
    }
  }

  .text,
  .logout,
  .name {
    font-weight: 600;
    // color: #fcfcfc;
    font-size: 18px;
    transition: color 0.3s ease;
  }
  .name {
    font-weight: 500;
    font-size: 16px;
  }

  .logout {
    width: 30px;
    height: 30px;
    cursor: pointer;
    &:hover {
      color: #676767b3;
    }
  }
  button {
    all: unset;
  }
}

@media (max-width: 600px) {
  .header {
    .text,
    .logout,
    .name,
    button {
      font-size: 13px;
      padding-right: 15px;
      &:last-child {
        padding-right: 0;
      }
    }
    .logout {
      width: 20px;
      height: 20px;
    }

    .imageContainer {
      img {
        height: 40px;
      }
    }
  }
}
