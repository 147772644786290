@import "../../mixins/mixins";

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.form {
  font-family: "Montserrat", sans-serif;
  padding: 40px 0;

  .imgContainer {
    display: flex;
    justify-content: center;
    img {
      width: 120px;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .form {
      border-radius: 7px;
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
      width: 400px;
      font-size: 15px;
      background-color: white;
      padding: 30px 40px;

      .inputContainer {
        display: flex;
        flex-direction: column;
        padding: 15px;
      }

      .spanText,
      .spanText2 {
        @include reusable-button-text;
        align-self: center;
        margin: 0 0 20px 0;
      }
      .spanText2 {
        margin: 0;
      }

      .error {
        color: red;
      }

      .input {
        @include reusable-input;
        @include focus-style(#ccc);
        width: 100%;
        margin-bottom: 15px;
      }
      .buttonLogin {
        @include reusable-button;
        @include interactive-bg(#fcfcfc);
        align-self: center;
        background-color: #fcfcfc;
        width: 200px;
        margin: 1rem 0 0 0;
      }
    }
  }
}

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  background-color: #eaeaea;
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.2);
  padding: 25px;
  border-radius: 4px;
  .modalheader {
    display: flex;
    justify-content: space-between;
    width: 100%;
    @media (max-width: 600px) {
      h2 {
        font-size: 18px;
      }
    }
  }
}

@media (max-width: 768px) {
  .container {
    height: 100%;
    width: 100%;

    .content {
      .form {
        width: 100%;
        height: 100%;
        box-shadow: none;
        box-sizing: border-box;
        border-radius: 0;
      }
    }
  }
  .modal {
    height: 100%;
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

@media (max-width: 450px) {
  .container {
    .content {
      .form {
        padding: 0;
      }
    }
  }
}
