@import "../../mixins/mixins";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  top: 75px;
  position: relative;
  font-family: "Montserrat", sans-serif;
  box-sizing: border-box;
  padding: 0 10px;
}

.loaderContainer {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  border-radius: 10px;
  padding: 15px;
  width: 95%;
  box-shadow: 0 0px 4px rgba(0, 0, 0, 0.1);
  color: #6367b1d4;
  transition: transform 0.3s ease, box-shadow 0.3s ease, color 0.3s ease;

  // &:hover {
  //   transform: translateY(-5px);
  //   box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  //   color: #373b90d4;
  // }

  .iconDelete {
    align-self: center;
    width: 40px;
    height: 40px;
    cursor: pointer;
    transition: transform 0.3s ease;

    &:hover {
      transform: translateY(3px);
    }
    &:active {
      color: darken(#6367b1d4, 5%);
    }

    .icon {
      width: 35px;
      height: 35px;
    }
  }

  h3 {
    align-self: center;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 7px;
    color: #828282;
  }
  .headerText {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .text {
      padding: 15px 10px;
      font-weight: 600;
    }

    .label {
      padding: 15px 10px;
      color: #7e7e7e;
    }
  }
}

.warnText {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  text-align: center;
  color: #666;
  font-size: 18px;

  span {
    align-self: center;
  }

  .rotatingIcon {
    font-size: 40px;
    color: #666;
    animation: spin 3s linear infinite;
    margin-bottom: 10px;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(-360deg);
    }
  }
}
