.loader {
  width: 35px;
  aspect-ratio: 1;
  display: grid;
  border-radius: 50%;
  background: linear-gradient(
        0deg,
        rgba(157, 157, 157, 0.7) 30%,
        hsla(0, 0%, 100%, 0) 0 70%,
        rgb(255, 255, 255) 0
      )
      50%/8% 100%,
    linear-gradient(
        90deg,
        rgba(157, 157, 157, 0.57) 30%,
        #ffffff00 0 70%,
        rgba(255, 255, 255, 0.75) 0
      )
      50%/100% 8%;
  background-repeat: no-repeat;
  animation: l23 1s infinite steps(12);
}
.loader::before,
.loader::after {
  content: "";
  grid-area: 1/1;
  border-radius: 50%;
  background: inherit;
  opacity: 0.915;
  transform: rotate(30deg);
}
.loader::after {
  opacity: 0.83;
  transform: rotate(60deg);
}
@keyframes l23 {
  100% {
    transform: rotate(1turn);
  }
}
